@import '../../constants/constants';

.privacy-policy-text {
    padding-right: 9%;
    padding-left: 9%;
    text-align: start;
}

.privacy-policy-text li {
    line-height: 160%;
}

@media screen and (max-width : $small-screen-size) {
    .privacy-policy-text {
        padding-bottom: 5%;
    }

    .privacy-policy-text p{
        margin-left: 0px;
    }
}

ul {
    padding-top: 0%;
    padding-left: 3%;
}

@media screen and (max-width : $small-screen-size) {
    .privacy-policy-text h1 {
        line-height: 1;
    }

    .privacy-policy-text h2 {
        line-height: 1;
    }
}

.privacy-policy-text h2 {
    padding-top: 0%;
    padding-bottom: 0%;
    text-transform: none;
}

/* Padding on anchors scrolling */
.privacy-policy-text h1{
    scroll-margin-top: 180px;
    scroll-snap-margin-top: 180px; /* iOS 11 and older */
}
