@import '../../constants/constants';

.header-menu {
    position:relative;
}

.header-menu .menu {
    background-color: black;
    box-shadow: 0 0 3px white;
    color: #fff;
    padding: 1.7% 20px;
    padding-right: 5%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    text-align: right;
}

@media screen and (max-width : $small-screen-size) {
    .header-menu .menu {
        padding-top: 3%;
        padding-bottom: 3%;
    }
}

@media screen and (max-width : $mobile-screen-size) {
    .header-menu .menu {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}

.header-menu .hide {
    display: none;
}

.header-menu .logo {
    width: 50%; 
    float: left;
    text-align: left;
    padding-left: 5%;
    font-size: 155%;
    font-weight: bold;
}

@media screen and (max-width : $small-screen-size) {
    .header-menu .logo {
        width:80%; 
        float:left;
        text-align: left;
        padding-left: 0%;
        font-size: 140%;
        font-weight: bold;
    }
}

.header-menu .logo:hover {
    opacity: 0.8;
}

.header-menu .menu a {
    padding-right: 20px;
    padding-left: 20px;
    text-decoration:none; 
    color: white;
    font-size: 130%;
}

.header-menu .logo a {
    text-decoration: none; 
    font-size: 100%;
}

@media screen and (max-width : $mobile-screen-size) {
    .header-menu .logo {
        width:85%; 
        float:left;
        text-align: left;
        padding-left: 0%;
        font-size: 105%;
        font-weight: bold;
    }

    .header-menu .menu a {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.header-menu .menu a:hover {
    opacity: 0.9;
}
  
.header-menu .sticky {
    background-color: rgba(255, 255, 255, 0.92);
    color: #333;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-menu .sticky a {
    color: black;
}

@media screen and (max-width : $small-screen-size) {
    .header-menu .sticky .toggle-button-line{
        background: black;
    }
}


/* Mobile menu */

.toggle-button {
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
    box-sizing: border-box;
    float:right;
  }
  
.toggle-button:focus {
    outline: none;
}

.toggle-button-line {
    width: 30px;
    height: 4px;
    background: white;
}
  
.side-menu {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.92);
    transition: all 0.3s ease;
    z-index: 9999;
  }
  
  .side-menu.open {
    right: 0;
  }

  .side-menu.open.black {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .side-menu.open.black a{
    color: white;
  }
  
  .side-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 20%;
  }
  
  .side-menu li {
    padding: 5%;
  }
  
  .side-menu a {
    display: block;
    color: #333;
    text-decoration: none;
    font-size: 130%;
  }
  
  .close-btn {
    display: fixed;
    margin: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 120%;
  }
  