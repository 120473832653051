@import '../constants/constants';

/* =================
Home start */

.home {
    text-align: center;
}

/* =================
Home end */

/* =================
Header start */

.app-header {
    background-color: black;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.app-header img {
    padding-top: 5%;
    width: 60%;
}

.app-header a {
    text-decoration:none; 
}

@media screen and (max-width : $small-screen-size) {
    .app-header {
        margin-bottom: 10%;
    }
}

.header-title {
    padding-top: 6%;
}

.ready-to-use {
    color: #1973e0;
}

.rest-api {
  color: #21de41;
}

.braces {
  color: #42e6f5;
}

.app-header h4 {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 3%;
}

/* =================
Header end */

/* =============
Introduction start */

.introduction-text {
    padding-right: 9%;
    padding-left: 9%;
    text-align: start;
    line-height:130%;
}

@media screen and (max-width : $small-screen-size) {
    .introduction-text {
        padding-bottom: 5%;
    }

    .introduction-text p{
        margin-left: 0px;
    }
}

/* =================
Introduction end */

/* =================
Response Info and Request Info start */

pre {
    display: block;
    width: 93%;
    margin: 7%;
    height: auto;
    text-align: left;
    font-size: 15px;
    background-color: rgba(3, 123, 252, .1);
    border-radius: 3px;
    padding-top: 30px;
    padding-bottom: 30px;
    border: 2px solid rgba(95, 69, 120, 0.1);
    overflow: scroll;
    max-height: 400px;
    max-width: 100vw;
}

code p {
    padding-left: 15px;
    line-height:130%;
}

.status-code {
    color: green;
}

.request-url {
    color: green;
}

.request-url-href {
    text-decoration:none; 
}

.request-url-href:hover {
    background:rgba(3, 123, 252, .1); 
}

@media screen and (max-width : $small-screen-size) {
    pre {
        max-width: 86vw;
        margin-left: 6%;
        width: 100%;
    }

    p {
        margin-left: 7%;
        text-align: left;
    }
    
    code p{
        margin-left: 0%;
        text-align: left;
    }
}

/* =================
Response Info and Request Info end */

/* =============
Endpoint start */

 
.request-method { 
    padding: 2%;
    margin-left: 5%;
    background: lightblue;
}

.endpoint-description {
    margin-left: auto;
    text-transform: uppercase;
    margin-right: 5%;
}

.request {
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
}

.request[value="not selected"]:hover {
    background: rgba(3, 123, 252, 0.04) !important;
}

.request-mobile {
    display: flex;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: 3%;
    margin-right: 3%;
}

.endpoint-description-mobile {
    text-transform: uppercase;
    margin-right: 5%;
    margin: auto;
}

.expand {
    margin-right: 5%;
    font-size: 180%;
}

/* =================
Endpoint end */

/* =============
ApiCallsInfo start */

.api-calls-info-div {
    display:inline-block;
    height: 100%;
    width: 100%;
}

.wrapper {
    display: grid;
    grid-auto-rows: minmax(90px, auto);
}

ul {
    padding-top: 22%;
    padding-left: 0%;
}

@media screen and (max-width : $small-screen-size) {
    ul {
        padding-top: 7%;
    }
}

h2 {
    padding-top: 3%;
    padding-bottom: 1%;
    text-transform: uppercase;
}

hr {
    width: 85%;
}

.column {
    float: left;
    width: 33.33%;
    border-spacing: 30px;
    border: 20px;
}

.row {
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
}

/* =================
ApiCallsInfo end */

/* =============
FAQ start */

.arrow-right {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
}

.faq-question {
    text-align: left;
    padding-left: 7%;
    padding-top: 1%;
    font-size: 115%;
}

.faq-question a {
    text-decoration: none; 
    color: black;
}

.faq-question a:hover {
    opacity: 0.6;
}

/* =================
FAQ end */

/* =============
GettingStarted start */

.getting-started {
    display:inline-block;
    height: 100%;
    width: 100%;
    line-height:130%;
}

.getting-started p {
    margin-left: 7%;
    text-align: left;
}

.getting-started code p{
    margin-left: 0%;
    text-align: left;
}

.getting-started-first pre{
    margin-left: 0%;
    width: 100%;
}

.getting-started-first p{
    margin-left: 0%;
    width: 100%;
}

.row .language {
    align-items: bottom;
    display: flex;
    align-items: flex-end;
}

.language {
    padding: 10px;
    justify-content: center;
}

.language-title {
    margin-bottom: 0px;
}

.getting-started img {
    min-width: 6px;
    width: 6%;
    max-height: 30px;
    max-width: 30px;
}

@media screen and (max-width : $small-screen-size) {

    .getting-started .column {
        float: left;
        width: 100%;
        padding-top: 4%;
    }

    .getting-started p {
        margin-left: 3%;
        margin-right: 3%;
    }

    .getting-started pre {
        margin-left: 3%;
    }

    .getting-started-first p {
        width: 94%;
    }

    .getting-started-first pre {
        margin-left: 3%;
        width: 94%;
    }

    .getting-started img {
        width: 5%;
    }
}

@media screen and (max-width : 1200px) {
    .getting-started img {
        width: 8%;
    }
}

/* =================
GettingStarted end */

/* =============
Footer start */

.footer {
    background: black;
    color: white;
    padding: 20px 0;
    margin-top: 5%;
    text-align: center;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width : $small-screen-size) {
    .footer {
        margin-top: 15%;
    }
}
  
.footer .menu {
    display: flex;
    justify-content: center;
    max-height: 100px;
}

.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

@media screen and (max-width : $small-screen-size) {
    .footer-links {
        flex-direction: column;
        justify-content: center;
    }
}
  
.footer ul.menu {
    padding-top: 0%;
    padding-bottom: 2%;
    padding-left: 0%;
    position: relative;
}

@media screen and (max-width : $small-screen-size) {
    .footer ul.menu {
        position: static;
    }
}

.footer .digital-ocean {
    position: absolute;
    top: 0;
    right: 2%;
}

@media screen and (max-width : $small-screen-size) {
    .footer .digital-ocean{
        position: static;
        padding-bottom: 4%;
    }
}
  
.footer .menu-item {
    list-style: none;
}
  
.footer .menu-link {
    font-size: 1.2rem;
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    opacity: 1;
}

.footer .menu-link:hover {
    opacity: 0.75;
}

.footer-copyright {
    opacity: 0.75; 
}

/* =============
Footer end */


/* =============
common start */

.body {
    text-align: center;
}

p {
    margin: 0 0 1.5em;
    font-size: 100%;
    line-height: 1.6em;
}

/* =============
common end */