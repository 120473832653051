@import '../../constants/constants';

.cookies-popup .ReactModal__Content {
    bottom: 20px;
    left: 20px;
    right: auto;
    bottom: 110px !important;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    max-width: 400px;
    overflow-x: auto;
    max-height: 50vh;
    overflow-y: auto;
  }

.cookies-popup .ReactModal__Content--after-open {
  position: fixed !important;
  bottom: 5vh !important;
  left: 5vh !important;
  right: 5vh !important;
  top: auto !important;
  overflow: auto !important;
  overflow-y: auto !important;
}

.cookies-popup .ReactModal__Overlay {
  background: rgba(255, 255, 255, 0) !important;
}

.cookies-popup .menu-link {
  text-decoration: none;
  color: #1973e0;
}

.cookies-popup .menu-link:hover {
  opacity: 0.75;
}

.manage-cookies {
  bottom: 25vh !important;
}

.manage-cookies-button {
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 2%;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.manage-cookies-button:hover {
  opacity: 0.8;
}

.accept-cookies-button {
  background-color: #1973e0;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 2%;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

.accept-cookies-button:hover {
  opacity: 0.8;
}

.cookies-popup-buttons {
  text-align: center;
}

.manage-cookies-modal .ReactModal__Content {
  bottom: 5% !important;
  top: 10% !important;
  left: 25% !important;
  right: 25% !important;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 50%;
  min-height: 75%;
}

.manage-cookies-modal .ReactModal__Content--after-open {
  position: fixed !important;
}

.manage-cookies h3 {
  text-align: center;
}

.toggle-box {
  display: inline-block;
  border-radius: 15px;
  width: 2.2em;
  height: 1.1em;
  cursor: pointer;
}

.toggle-box-ball {
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin: 1px;
  transition: transform 0.2s ease-in-out;
}

.manage-cookies {
  font-size: 150%;
  margin-left: 5%;
}

.manage-cookies .toggle-box {
  float: right;
  margin-right: 5%;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 6vh;
  font-weight: bold;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #aaa;
  padding: 0;
  margin: 0;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

.close-icon {
  display: block;
  position: relative;
  top: 0.5vh;
  right: 1.5vh;
}

.save-cookies-button {
  margin: 0 auto;
  text-align: center;
}

.save-cookies-button button {
  background-color: #1973e0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 3vh;
  bottom: 3vh;
  right: 5vh;
}

.save-cookies-button button:hover {
  opacity: 0.8;
}

.cookie-selection {
  position: relative;
}

.cookies-description {
  width: 75%;
  padding-right: 5%;
}

.cookies-description h5 {
  margin: 0;
  margin-top: 5%;
  font-weight: normal;
  color: #373737;
  font-size: 70%;
  bottom: 0;
}

.cookie-selection .cookies-description, .cookie-selection .cookies-toggle {
  display: inline-block;
}

.cookie-selection label {
  float: right;
}

.cookies-toggle {
  width: 15%;
  height: 100%;
  position: absolute;
  top: 0;
  padding-left: 5%;
}

@media screen and (max-width : $small-screen-size) {
  .manage-cookies-modal .ReactModal__Content {
    left: 15% !important;
    right: 15% !important;
    max-width: 70%;
  }

  .cookies-description h5 {
    font-size: 75%;
  }

  .manage-cookies h3{
    font-size: 100%;
  }

  .cookies-description span {
    font-size: 95%;
  }
}

@media screen and (max-width : $mobile-screen-size) {
  .cookies-description h5 {
    font-size: 55%;
  }

  .manage-cookies h3{
    font-size: 80%;
  }

  .cookies-description span {
    font-size: 70%;
  }

  .toggle-box {
    border-radius: 9px;
    width: 1.5em;
    height: 0.75em;
  }
  
  .toggle-box-ball {
    display: flex;
    width: 0.67em;
    height: 0.67em;
    margin: 0;
    margin-top: 0.05em;
    margin-left: 0.04em;
  }

  .manage-cookies .toggle-box {
    float: none;
  }
}