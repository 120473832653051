@import '../../constants/constants';

.theory .footer{
    margin-top: 0;
}

.theory-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 400px;
}

@media screen and (min-width : $small-screen-size) {

    .theory-content-main {
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 2%;
        margin-right: 1.5%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.22);
    }
}

.theory-menu {
    width: 14%;
    text-align: left;
    padding-left: 1.5%;
    padding-right: 1%;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 1.5%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.22);
    flex-shrink: 0;
    align-self: flex-start;
    position: sticky;
    top: 5%;
}

.theory-menu-link {
    font-size: 1rem;
    text-decoration: none;
    opacity: 1;
    color: rgb(11, 52, 186);
}

.theory-menu ul {
    padding-left: 0;
}

.theory-menu-ul li{
    margin-bottom: 12%;
}

.theory-menu-mobile {
    text-align: left;
    padding-left: 7%;
}

.theory-menu-mobile-ul li{
    padding: 4px;
}


.theory-menu-item {
    list-style: none;
}

.theory-menu-link:hover {
    opacity: 0.6;
}

.theory-content-main {
    width: 88%;
}

.theory-content {
    padding-right: 7%;
    padding-left: 4%;
    line-height:140%;
    text-align: start;
    white-space: pre-wrap;
}

.theory-content pre {
    margin: 0;
    max-height: 1000px;
}

.theory-content-main h2 {
    font-size: 170%;
}

.theory-content div {
    padding-right: 2%;
    padding-left: 2%;
}

@media screen and (max-width : $small-screen-size) {

    .theory-content p{
        margin-left: 0px;
    }
}

/* Padding on anchors scrolling */
.theory-content-main h3 {
    scroll-margin-top: 130px;
    scroll-snap-margin-top: 130px; /* iOS 11 and older */
}

.theory-content div {
    scroll-margin-top: 150px;
    scroll-snap-margin-top: 150px; /* iOS 11 and older */
}

.rest-fundamentals-img img {
    display: block;
    margin: 0 auto;
    max-width: 80%;
    max-height: 80%;
    padding-bottom: 2%;
}

pre, code {
    white-space: pre-wrap;
}

@media screen and (max-width : $small-screen-size) {

    .theory-content {
        padding-right: 0%;
        padding-left: 7%;
    }
}