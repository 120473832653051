.adds-banner img {
    width: 60%;
    height: 60%;
    padding-top: 3%;
}

.adds-banner-full-width img{
    width: 100%;
    height: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.adds-banner img:hover {
    opacity: 0.8;
    cursor: grab;
}

.adds-banner-full-width img:hover {
    opacity: 0.8;
    cursor: grab;
}

@media screen and (max-width : 1200px) {
    .adds-banner img {
        width: 75%;
        height: 75%;
    }
}

@media screen and (max-width : 800px) {
    .adds-banner img {
        width: 90%;
        height: 90%;
    }
}

@media screen and (max-width : 600px) {
    .adds-banner img {
        height: 100%;
    }
}