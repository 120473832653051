@import '../../constants/constants';

.support-us-text {
    padding-right: 9%;
    padding-left: 9%;
    text-align: start;
}

.donate-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width : $small-screen-size) {
    .support-us-text {
        padding-bottom: 5%;
    }

    .support-us-text p{
        margin-left: 0px;
    }
}